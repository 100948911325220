body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-tel-input input.phone {
  width: 100%;
}


.consent .MuiTypography-body1 {
  font-size: .85rem;
  color: #444;
}

#images .carousel .thumbs-wrapper {
  margin: 0;
}

#images .carousel .thumb.selected {
  border: 3px solid #999;
}

.chat-canvas {
  padding: 15px;
  flex-grow: 1;
}

.chat-bg {
  background: url("https://storage.googleapis.com/bakestreet-f55a0.appspot.com/images/bg/connect_bg.png");
  background-repeat: repeat;
}


/* stripe */
.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#card-element {
  padding: 14px;
  border-radius: 4px;
  border: 2px solid #cacaca;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#card-element:focus,
#card-element.StripeElement--focus {
  border: 2px solid #78374a;
}
/* stripe */

.ql-container { font-size: 1rem; }
.q-container > p { padding: 0; margin: 0 }
.q-container > ol { margin: 0 }
.q-container > ul { margin: 0 }

/* Google Maps Autocomplete */
.pac-container {
  z-index: 9999 !important;
}